var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasParamsId && _vm.getFleetTagsName.length > 0
    ? _c(
        "div",
        [
          _vm.inline
            ? [
                _vm._l(_vm.getFleetTagsName, function (item, index) {
                  return _c(
                    "span",
                    { key: index },
                    [
                      index < 3
                        ? _c(
                            "b-badge",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: { variant: "primary" },
                            },
                            [_vm._v(_vm._s(item))]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                _vm.getFleetTagsName.length > 3
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          "padding-left": "5px",
                          cursor: "pointer",
                        },
                        attrs: { id: "popover-target-1" },
                      },
                      [
                        _c("b-badge", { attrs: { variant: "primary" } }, [
                          _vm._v("..."),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.getFleetTagsName.length > 3
                  ? _c(
                      "b-popover",
                      {
                        attrs: {
                          target: "popover-target-1",
                          triggers: "hover",
                          placement: "top",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_c("span", [_vm._v("Todas as Tags")])]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1899382840
                        ),
                      },
                      _vm._l(_vm.getFleetTagsName, function (item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticStyle: { "padding-left": "5px" },
                          },
                          [
                            _c("b-badge", { attrs: { variant: "primary" } }, [
                              _vm._v(_vm._s(item)),
                            ]),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            : [
                [
                  _c(
                    "h4",
                    {
                      staticClass: "card-title mt-4",
                      staticStyle: { display: "flex" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("fleet.texts.fleettags")) + " "
                      ),
                    ]
                  ),
                  _vm._l(_vm.groupedTags, function (tags, category) {
                    return _c(
                      "div",
                      { key: category },
                      [
                        _c("br"),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(`fleetProfile.tagsCategory.${category}`)
                            )
                          ),
                        ]),
                        _vm._l(tags, function (item, index) {
                          return _c(
                            "p",
                            { key: index },
                            [
                              _c("b-badge", { attrs: { variant: "primary" } }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                              _vm._v(" - "),
                              _c(
                                "span",
                                {
                                  staticClass: "font-italic",
                                  staticStyle: { "font-size": "small" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-dark font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.createdBy
                                              ? item.createdBy.replace(
                                                  "@kovi.com.br",
                                                  ""
                                                )
                                              : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("span", { staticClass: "text-dark" }, [
                                    _vm._v(" em "),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-dark font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm
                                              .$moment(item.createdAt)
                                              .local()
                                              .format("DD/MM/YYYY HH:mm")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  }),
                ],
              ],
        ],
        2
      )
    : _vm.loading && _vm.inline && !_vm.error
    ? _c("b-spinner", {
        staticStyle: { width: "13px", height: "13px", "margin-left": "13px" },
        attrs: { variant: "primary" },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }