<template>
  <span v-if="isBrazil() && !loading && vehicleMonitored.licensePlate" class="text-muted" style="padding-left: 5px">
    <b-badge class="font-weight-normal" :variant="getBadgeConfig.status">MULTAS {{ getBadgeConfig.text }}</b-badge>
  </span>
  <b-spinner v-else-if="loading && !error" style="width: 13px; height: 13px; margin-left: 9px;" />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { isBrazil } from '@utils/helper-region';

export default {
  props: {
    licensePlate: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      loading: true,
      error: false
    }
  },
  computed: {
    ...mapState('tickets', ['vehicleMonitored']),
    ...mapGetters({
      profile: 'fleet/header',
    }),
    getBadgeConfig () {
      return this.vehicleMonitored.isMonitored ?
        { text : 'ATIVADO', status: 'success' } :
        { text : 'DESLIGADO', status : 'danger' };
    },
  },
  mounted() {
    this.vehicleIsMonitored(this.licensePlate)
      .catch(() => {
        this.error = true;
      })
      .finally(() => this.loading = false)
  },
  methods: {
    ...mapActions('tickets', ['vehicleIsMonitored']),
    isBrazil,
  }
}
</script>
