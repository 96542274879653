var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isBrazil() && !_vm.loading && _vm.vehicleMonitored.licensePlate
    ? _c(
        "span",
        { staticClass: "text-muted", staticStyle: { "padding-left": "5px" } },
        [
          _c(
            "b-badge",
            {
              staticClass: "font-weight-normal",
              attrs: { variant: _vm.getBadgeConfig.status },
            },
            [_vm._v("MULTAS " + _vm._s(_vm.getBadgeConfig.text))]
          ),
        ],
        1
      )
    : _vm.loading && !_vm.error
    ? _c("b-spinner", {
        staticStyle: { width: "13px", height: "13px", "margin-left": "9px" },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }