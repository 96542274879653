<template>
  <div v-if="hasParamsId && getFleetTagsName.length > 0">
    <template v-if="inline">
      <span v-for="(item, index) in getFleetTagsName" :key="index">
        <b-badge v-if="index < 3" style="margin-left: 5px;" variant="primary">{{ item }}</b-badge>
      </span>
      <span v-if="getFleetTagsName.length > 3" id="popover-target-1" style="padding-left: 5px;  cursor: pointer">
        <b-badge variant="primary">...</b-badge>
      </span>
      <b-popover v-if="getFleetTagsName.length > 3" target="popover-target-1" triggers="hover"
                 placement="top"
      >
        <template #title>
          <span>Todas as Tags</span>
        </template>
        <span v-for="(item, index) in getFleetTagsName" :key="index" style="padding-left: 5px;">
          <b-badge variant="primary">{{ item }}</b-badge>
        </span>
      </b-popover>
    </template>
    <template v-else>
      <template>
        <h4 class="card-title mt-4" style="display: flex;">
          {{ $t('fleet.texts.fleettags') }}
        </h4>
        <div v-for="(tags, category) in groupedTags" :key="category">
          <br />
          <strong>{{ $t(`fleetProfile.tagsCategory.${category}`) }}</strong>
          <p v-for="(item, index) in tags" :key="index">
            <b-badge variant="primary">{{ item.name }}</b-badge> -
            <span class="font-italic" style="font-size: small;">
              <span class="text-dark font-weight-bold">
                {{ item.createdBy ? item.createdBy.replace('@kovi.com.br', '') : 'N/A' }}
              </span>
              <span class="text-dark">
                em
              </span>
              <span class="text-dark font-weight-bold">
                {{
                  $moment(item.createdAt)
                    .local()
                    .format('DD/MM/YYYY HH:mm')
                }}
              </span>
            </span>
          </p>
        </div>
      </template>
    </template>
  </div>
  <b-spinner v-else-if="loading && inline && !error" style="width: 13px; height: 13px; margin-left: 13px;" variant="primary" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      error: false
    }
  },
  computed: {
    ...mapGetters('user', ['findGroups', 'authToken']),
    ...mapGetters('fleet', ['getFleetTagsName', 'getFleetTagsObj']),
    hasParamsId () {
      return !!this.$route.params?.id
    },
    groupedTags() {
      return this.getFleetTagsObj.reduce((acc, tag) => {
        (acc[tag.category] = acc[tag.category] || []).push(tag);
        return acc;
      }, {});
    },
  },
  mounted() {
    const { id } = this.$route.params;
    const headers = { Authorization: this.authToken };
    this.loading = true;
    this.getFlags({ headers, id })
      .catch(() => this.error = true)
      .finally(() => this.loading = false);
  },
  methods: {
    ...mapActions('fleet', ['getFlags']),
  }
}
</script>
